/* tslint:disable */
/* eslint-disable */
/**
 * SteadyMD Partner API
 * The Consult API allows you to find availability for Clinicians in your programs, create scheduled or on-demand Consults, and view the status of Consults in the SteadyMD Clinic.
 *
 * The version of the OpenAPI document: 1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export * from "./api";
export * from "./configuration";
